import "./vendors.js";
import "./css/index.css";
import "./css/landing.css";
import image from './assets/Logo/JPG/nuhieh_Artboard 3 copy.jpg';

// // Function to initialize navigation links with redirection logic based on login status
// function initNavigation() {
//     const homeLink = document.querySelector(".nav-link.home");
//     const blogLink = document.querySelector(".nav-link.blog");

//     // Set default active page (Home)
//     setActiveLink(homeLink);

//     // Event listener for home link
//     homeLink.addEventListener("click", () => {
//         setActiveLink(homeLink);
//     });

//     // Event listener for blog link with login check
//     blogLink.addEventListener("click", (e) => {
//         e.preventDefault();
//         const token = sessionStorage.getItem("token");
//         const user = sessionStorage.getItem("userId");

//         if (token && user) {
//             setActiveLink(blogLink);
//             window.location.href = "Blog.html";
//         } else {
//             sessionStorage.setItem("redirect", "Blog.html");
//             window.location.href = "LogIn.html";
//         }
//     });
// }

// // Helper function to set active link
// function setActiveLink(link) {
//     document.querySelectorAll(".nav-link").forEach((navLink) => {
//         navLink.classList.remove("active");
//     });
//     link.classList.add("active");
// }



// // Function to initialize the "User Account" link with redirection logic
// function initUserAccount() {
//     document.querySelectorAll("#useraccount").forEach((element) => {
//         element.addEventListener("click", () => {
//             const token = sessionStorage.getItem("token");
//             const user = sessionStorage.getItem("userId");

//             if (token && user) {
//                 window.location.href = "profile.html";
//             } else {
//                 sessionStorage.setItem("redirect", "profile.html");
//                 window.location.href = "LogIn.html";
//             }
//         });
//     });
// }

// // Function to initialize the "Try Nuhieh" link with redirection logic
// function initTryNuhieh() {
//     document.querySelectorAll("#tryNuhieh").forEach((element) => {
//         element.addEventListener("click", () => {
//             const token = sessionStorage.getItem("token");
//             const user = sessionStorage.getItem("userId");

//             if (token && user) {
//                 window.location.href = "tool.html";
//             } else {
//                 sessionStorage.setItem("redirect", "tool.html");
//                 window.location.href = "LogIn.html";
//             }
//         });
//     });
// }
// Function to slide the logos in the landing page
function logosSlide() {
    var logo = document.querySelector(".logos");
var copy = document.querySelector(".logos-slide").cloneNode(true);
console.log(copy);
console.log(logo);
logo.appendChild(copy);
}

// get the token and user from the local storage
var postsArray = [];

function calculateReadingTime(text) {
    if (!text) {
        return "اقل من ١";
    } else {
        const wordsPerMinute = 250; // Average reading speed (words per minute)
        const textLength = text.trim().split(/\s+/).length; // Split text by whitespace and count words
        const readingTimeMinutes = Math.ceil(textLength / wordsPerMinute); // Calculate minutes, round up
        return readingTimeMinutes;
    }
    cz;
}

function formatPosts(posts) {
    return posts.map((post) => {
        // convert the date to a readable format in Arabic
        post.created_at = new Date(Date.parse(post.created_at)).toLocaleDateString(
            "ar-EG",
            {
                year: "numeric",
                month: "long",
                day: "numeric",
            }
        );
        // calculate the reading time for each post ensuring it's in Arabic and wrpped in `` to be a string
        post.readTime = `${calculateReadingTime(post.body)}د للقراءة`;

        console.log(post.body);
        post.body = post.body.replace(/\n/g, "<br><br>").replace(/(\d\.)/g, "<br><br><strong>$1</strong>"); // Emphasize steps with numbers
        
        console.log(post.body);

        // update the strcture of the post object to match the HTML structure in the gridView function
        postsArray.push({
            id: post.id,
            title: post.title,
            image: post.image,
            category: post.category,
            readTime: post.readTime,
            created_at: post.created_at,
            created_by: post.created_by,
            body: post.body,
        });
    });
}

// function to view 5 posts in the landing page , and handle if the posts are less than 5 
function postsView() {
    var postContainer = document.querySelector(".landingPosts");
    postContainer.innerHTML = "";
    // use Switch to handle the number of posts , use innerHtml to add the posts to the container 
    switch (postsArray.length) {
        case 0:
            postContainer.innerHTML = `
            <div class="row my-3">
            <h1 class="text-center">لا يوجد منشورات</h1>
            </div>
            `;
            break;
        case 1:
            postContainer.innerHTML = `
            <div class="row my-3 R1">
                    <div class="col-12">
                        <div class="card mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        case 2:
            postContainer.innerHTML = `
            <div class="row mt-4  R2">
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        case 3:
            postContainer.innerHTML = `
                        <div class="row my-3 R1">
                    <div class="col-12">
                        <div class="card mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row R2">
                    <div class="col-md-4">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[2].id}'">
                            <img class="mh-75" src="${postsArray[2].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[2].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        case 4:
            postContainer.innerHTML = `
            <div class="row mt-4  R2">
                    <div class="col-md-4">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5 R3">
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[2].id}'">
                            <img class="mh-75" src="${postsArray[2].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[2].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[3].id}'">
                            <img class="mh-75" src="${postsArray[3].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[3].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
        default:
            postContainer.innerHTML = `
            <div class="row my-3 R1">
                    <div class="col-12">
                        <div class="card mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[0].id}'">
                            <img class="mh-75" src="${postsArray[0].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[0].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row R2">
                    <div class="col-md-4">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[1].id}'">
                            <img class="mh-75" src="${postsArray[1].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[1].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[2].id}'">
                            <img class="mh-75" src="${postsArray[2].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[2].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5 R3">
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[4].id}'">
                            <img class="mh-75" src="${postsArray[4].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[4].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-3 mainPost text-bg-dark" onclick="window.location.href='ArticlePage.html?postId=${postsArray[3].id}'">
                            <img class="mh-75" src="${postsArray[3].image || "/assets/images/nuhieh_Artboard 3 copy.jpg"}" alt="post image">
                            <div class="card-img-overlay ">
                                <h1 class="card-title my-4">
                                    ${postsArray[3].title || "عنوان المنشور"}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" my-4  text-center">
                    <a class="restOfBlog btn btn-outline-secondary" href="#">
                        باقي المدونات ←
                    </a>
                </div>
            `;
            break;
    }

    // Select the blog element and check its existence
var goToBlog = document.querySelector(".restOfBlog");

if (goToBlog) {
    goToBlog.addEventListener("click", function () {
        // Retrieve and log the token and userId for debugging

        const token = sessionStorage.getItem("token");
                    const user = sessionStorage.getItem("userId");
        console.log("Token:", token);  
        console.log("User ID:", user);

        // Redirect to login if token or userId is missing
        if (!token || !user) {
            sessionStorage.setItem("redirect", "Blog.html"); // Store redirect path
            console.log(sessionStorage.getItem("redirect"));
            console.log("User not logged in. Redirecting to LogIn page.");
            window.location.href = "LogIn.html";
        } else {
            console.log("User logged in. Redirecting to Blog page.");
            window.location.href = "Blog.html";
        }
    });
} else {
    console.error("Element '.restOfBlog' not found. Make sure the selector is correct.");
}

}

// get posts from the backend
async function getPosts() { 
    if (!navigator.onLine) {
        alert("لا يوجد اتصال بالإنترنت");
        return;
    }
    try {
        const response = await fetch(
            "https://nuhieh-backend-g6cybsc7e9dqerh8.canadacentral-01.azurewebsites.net/blog/posts"
        );
        if (!response.ok) {
            console.log("!response.ok: " + response.error);
            const errorText = await response.text();
            console.log("فشل جلب المنشورات: " + errorText);
            return;
        }
        const responseData = await response.json();
        if (responseData.error) {
            console.log("error: " + responseData.error);
        } else {
            formatPosts(responseData.data);
            postsView();
            console.log("postsArray.length: " + postsArray.length);
        }
    } catch (error) {
        console.log("catch: " + error.message);
    }
}



// Initialize the entire landing page functionality
async function initLandingPage() {
    const { initNavigation, initUserAccount, initTryNuhieh } = await import("./shared");

    initNavigation();
    initUserAccount();
    initTryNuhieh();
    logosSlide();
    getPosts();
const JoinUs = document.querySelector(".JoinUs");

JoinUs.addEventListener("click", function () {
    window.location.href = "SignUp.html";
});
}

document.addEventListener("DOMContentLoaded", initLandingPage);
